<template>
	<section class="add-qcm-container">
		<h1>Modifier une question</h1>
		<form @submit.prevent="submit()" class="form-container">
			<div class="filter-input-container">
				<div class="input-container">
					<label for="niveaux">Niveau</label>
					<select id="niveaux" v-model="question.level" required>
						<option
							v-for="level in this.$store.state.levels"
							:key="level"
							:value="level.id"
						>
							{{ level.name }}
						</option>
					</select>
				</div>
				<div class="input-container">
					<label for="subject">Matière</label>
					<select id="subject" v-model="question.subject" required>
						<option
							v-for="level in this.$store.state.subjects"
							:key="level"
							:value="level.id"
						>
							{{ level.name }}
						</option>
					</select>
				</div>
				<div class="input-container">
					<label for="chapter">Chapitre</label>
					<select v-model="question.chapter" name="chapter" id="chapter">
						<option :value="null">-----</option>
						<optgroup
							v-for="sequence in filteredSequences(this.question.subject)"
							:key="sequence.id"
							:label="sequence.name"
						>
							<option
								v-for="chapter in this.$store.getters.chapters(
									sequence.id
								)"
								:key="chapter.id"
								:value="chapter.id"
							>
								{{ chapter.name }}
							</option>
						</optgroup>
					</select>
				</div>				
				<div class="input-container">
					<label for="theme">Difficulté</label>
					<select id="theme" v-model="question.difficulty" required>
						<option
							v-for="difficulty in this.$store.state.difficulties"
							:key="difficulty"
							:value="difficulty.id"
						>
							{{ difficulty.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="title-question-container" v-if="question.question">
				<label for=""><h2> Question</h2></label>
				<LatexEditor :text="question.question" :customId="'question'" @update-text="updateLatex" />
			</div>
			<div class="input-file-container">
				<img width="200" :src="displayedImg" alt="">
				<label for="image">Image</label>
				<input  @change="updateImg" id="image" type="file">
			</div>

			<div class="answers-container">
				<div v-for="(answer,index) in answers" :key="answer" class="answer-wrapper">
					<input v-model="answer.is_correct" type="checkbox" :name="`answer${index +1}`" :id="`answer${index +1}`">
					<LatexEditor :text="answer.answer" :customId="`answerText${index}`" @update-text="(text) => updateAnswer(text,index)" />
				</div>
			</div>

			<button class="submit dark">Valider</button>
		</form>
	</section>
</template>
<script>
import { getQCMQuestionService,updateQCMQuestionService } from '../../../services/qcmQuestionsServices'
import { updateQcmQuestionAnswerService } from '../../../services/qcmQuestionAnswersServices'
import LatexEditor from '../../Utils/LatexEditor.vue'
import { authenticatedImageFetch } from '../../../utils'

export default {
	components: {
		LatexEditor
	},
	data() {
		return {
			question: {
				level: '',
				subject: '',
				chapter: '',
				difficulty: '',
				title: '',
				question: '',
				image: null,
			},
			displayedImg: '',
			answers: []
		}
	},
	methods: {
		updateImg(event) {
			const file = event.target.files[0]
			this.question.image = file
			const reader = new FileReader()
			reader.onload = (e) => {
				this.displayedImg = e.target.result
			}
			reader.readAsDataURL(file)
		},
		updateLatex(latex) {
			this.question.question = latex
		},
		updateAnswer(answer,index) {
			this.answers[index].answer = answer
		},
		isValid() {
			if (this.question.level === null) {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez selectionner un niveau",
				}
				return false
			}
			if (this.question.subject === null) {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez selectionner une matière",
				}
				return false
			}
			
			if (this.question.difficulty === null) {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez selectionner une difficulté",
				}
				return false
			}
			
			if (this.question.question === "") {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez saisir une question",
				}
				return false
			}
			for (const answer of this.answers) {
				if (answer.answer === "") {
					this.$store.state.toast = {
						status: 'error',
						text: "Veuillez saisir une réponse",
					}
					return false
				}
			}
			if (this.answers.filter(answer => answer.is_correct).length === 0) {
				this.$store.state.toast = {
					status: 'error',
					text: "Veuillez selectionner une bonne réponse",
				}
				return false
			}
			return true
			
		},
		async submit(){
			if(!this.isValid()) return

			const formData = new FormData()
			formData.append('level', this.question.level)
			formData.append('subject', this.question.subject)
			if (this.question.chapter !== null){
				formData.append('chapter', this.question.chapter)
			}
			formData.append('difficulty', this.question.difficulty)
			formData.append('question', this.question.question)
			console.log(typeof this.question.image)
			if(typeof this.question.image === 'object' && this.question.image !== null) {
				console.log(this.question.image)

				formData.append('image', this.question.image)
			}
			console.log(formData)

			await updateQCMQuestionService(this.$route.params.id, formData)

			for (const answer of this.answers) {
				await updateQcmQuestionAnswerService(answer.id, answer)
			}

			this.$store.state.toast = {
				status: 'success',
				text: "Question modifiée avec succès",
			}
			this.$router.go(-1)
		},
		async getImg(){
			const res = await authenticatedImageFetch(this.question.image)
			.then(res => res.blob())
			this.displayedImg = URL.createObjectURL(res)
		},
		async getQuestion(){
			this.question = await getQCMQuestionService(this.$route.params.id)

			this.answers = this.question.answers
			if(this.question.image){
				this.getImg()
			}

			const chapter = this.$store.getters.chapter(this.question.chapter)

			const sequence = this.$store.state.sequences.find(sequence => sequence.id === chapter.sequence)
			this.question.subject = sequence.subject
		},
		filteredSequences(subjectId) {
			const sequences = this.$store.getters.sequences(this.question.level)

			if(!sequences) return []
			const filteredSequences = sequences.filter(sequence => sequence.subject === subjectId)
			
			return filteredSequences
			
		}
	},
	created() {
		this.getQuestion()
	},
	watch: {
		"question.subject": function() {
			this.question.chapter = null
		},
		"question.level": function() {
			this.question.chapter = null
		}
	}
}
</script>
